<template>
  <span v-if="tablesDataList.length > 0">
    <div
      v-for="(table, index) in tablesDataList"
      ref="childTable"
      :key="index"
    >
      <v-card
        v-if="table.info && table.info.pllistid && table.info.id === null"
        :id="'dataGrid-' + table.info.virtid"
        :ref="'dataGrid-' + table.info.virtid"
        class="pa-5 ma-0"
        style="background-color: white"
        flat
      >
        <v-row class="grey lighten-5">
          <v-col>
            <h3 class="mb-2">
              {{ table.info.title }}
              <span v-if="table.info.id">
                [{{ table.info.id }}]
              </span>
            </h3>
          </v-col>
          <v-col
            v-if="canEditReport"
            class="text-right"
          >
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  dark
                  icon
                  :color="'blue-grey lighten-3'"
                  :loading="gettingObjects"
                  v-on="on"
                  @click="newTableDialogStart(table.info)"
                >
                  <v-icon large>
                    mdi-plus-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Добавить таблицу</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
      <get-table
        v-else-if="table.info && table.info.flgcollapsed"
        :id="'dataGrid-' + table.info.id"
        :key="'tableVf-' + table.info.id"
        :info="table.info"
        @updateTableListAndDataById="updateTableListAndDataById"
      />
      <v-card
        v-else-if="table.info&&table.info.id"
        :id="'dataGrid-' + table.info.id"
        :key="'tableVf-' + table.info.id"
        class="pa-5 mt-0"
        style="background-color: white"
        flat
      >
        <table-vf
          :active-table="activeTable"
          :can-edit="canEditReport"
          :getting-objects="gettingObjects"
          :rightdebug="rightdebug"
          :rightadmin="rightadmin"
          :rightadvanced="rightadvanced"
          :show-table-title="true"
          :table="table"
          @changeActiveTable="setActiveTable"
          @delTable="delTable"
          @showSnapHistory="showSnapHistory"
          @showChart="showChart(table.info.id)"
          @showhint="setShowInfoValue"
          @updateTableData="updateTableData"
          @updateTableDataLite="updateTableDataLite"
          @updateTablesData="updateTableListAndDataById"
        />
        <table-chart
          v-if="((showCharts && table.info.chpattern) || showTableChart[table.info.id]) && (table.info.chpattern || rightadmin)"
          :right-admin="rightadmin"
          :table-data="table"
        />
      </v-card>
    </div>
    <v-dialog
      v-if="addTableDialog"
      v-model="addTableDialog"
      width="640"
    >
      <v-card>
        <v-card-title>
          {{ addTableListTitle }}
        </v-card-title>
        <v-card-text class="d-flex">
          <v-autocomplete
            ref="selects"
            v-model="objectid"
            :items="objectsList"
            item-value="listitemdataid"
            item-text="searchtext"
            dense
            flat
            hide-no-data
            hide-details
            full-width
            class="withoutbg mt-0 pl-2"
            :disabled="addingNewTable"
            @keydown.enter.native.stop.prevent="addNewTable"
          >
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" />
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.searchtext }}
                    <!--  <i>(id:{{ data.item.listitemdataid }})</i> -->
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <span v-if="objectsListInfo && !!objectsListInfo.flgeditable">
            <v-btn
              dark
              icon
              :color="'blue-grey lighten-3'"
              :disabled="addingNewTable"
              @click="showVirtualObjectDialog()"
            >
              <v-icon large>
                mdi-plus-box
              </v-icon>
            </v-btn>
            <v-btn
              v-if="objectid && canEditObject(objectid)"
              dark
              icon
              :color="'blue-grey lighten-3'"
              :disabled="addingNewTable"
              @click="showVirtualObjectDialog(objectid)"
            >
              <v-icon large>
                mdi-pencil-box
              </v-icon>
            </v-btn>
            <v-btn
              v-if="objectid && canEditObject(objectid)"
              dark
              icon
              :color="'blue-grey lighten-3'"
              :disabled="addingNewTable"
              @click="delVirtualObject(objectid)"
            >
              <v-icon large>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="addingNewTable"
            @click="addNewTable"
          >
            добавить
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="virtualObject.show"
      v-model="virtualObject.show"
      max-width="580"
    >
      <v-card>
        <v-card-title>
          {{ virtualObject.id ? 'Редактирование объекта' : 'Добавление нового объекта' }}
        </v-card-title>
        <v-card-text class="d-flex">
          <v-text-field
            v-model="virtualObject.title"
            @keydown.enter="updateVirtualObject"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            @click="updateVirtualObject"
          >
            {{ virtualObject.id ? 'Изменить' : 'Добавить' }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="closeVirtualObjectDialog"
          >
            отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="addRowDialog"
      width="500"
      :retain-focus="false"
    >
      <v-card>
        <v-card-text>
          <v-autocomplete
            v-if="!canAddNewObjects"
            v-model="selectetObject"
            :items="objectsList"
            item-text="title"
            label="Объект для добавления"
            placeholder="Выберете объект"
            :messages="comboMessages"
            dense
            solo
            flat
            return-object
            hide-no-data
            full-width
            @keydown.enter="addRow(addToTable)"
          >
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" />
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.title }} <i>(id:{{ data.item.listitemdataid }})</i>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-combobox
            v-if="canAddNewObjects"
            v-model="selectetObject"
            :items="objectList"
            item-text="title"
            item-value="listitemdataid"
            label="Объект для добавления"
            placeholder="Выберете объект"
            :messages="comboMessages"
            dense
            solo
            flat
            hide-no-data
            full-width
          >
            <template #item="data">
              <template v-if="typeof data.item !== 'object'">
                <v-list-item-content v-text="data.item" />
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ data.item.title }} <i>(id:{{ data.item.listitemdataid }})</i>
                  </v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-combobox>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="error"
            text
            @click="addRowDialog = false"
          >
            Отмена
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="addRow(addToTable)"
          >
            Добавить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mdialog ref="dlgDel" />
    <cell-formula-dialog v-if="showCellFormula" />
    <v-dialog
      v-model="snapshotDialog"
      max-width="580"
    >
      <v-card>
        <v-card-title>
          История изменений
        </v-card-title>
        <v-card-text>
          <v-simple-table
            fixed-header
            height="400px"
          >
            <template #default>
              <thead>
                <tr>
                  <th class="text-left">
                    Дата
                  </th>
                  <th class="text-left">
                    Автор
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in snapshotlist"
                  :key="item.plsnapshotid"
                  style="cursor: pointer"
                  @click="showSnapshotInfo(item.plsnapshotid)"
                >
                  <td class="pl-2">
                    {{ item.snapshotdtstr }}
                  </td>
                  <td>{{ item.usertitle }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div
      v-if="showInfoValue && rightdebug && showDebug"
      class="addition-info"
    >
      <p>
        {{ addLineBreak(showInfoValue) }}
      </p>
    </div>
  </span>
  <span v-else-if="dataLoading">
    <template v-for="tables in tablesList">
      <v-skeleton-loader
        :key="tables.id"
        class="mx-6 my-3"
        type="table-heading, image"
      />
    </template>
  </span>
</template>

<script>
  // import xtable from './testtable.json'
  export default {
    components: {
      cellFormulaDialog: () => import('./components/cellFormulaDialog.vue'),
      getTable: () => import('./components/getTable.vue'),
      tableChart: () => import('./tableChart'),
      tableVf: () => import('./tableVfViewOnClass'),
      mdialog: () => import('../../../components/mdialog'),
    },
    props: {
      tablesList: {
        type: Array,
        default: () => [],
      },
      tab: {
        type: String,
        default: '',
      },
      activeTab: {
        type: Number,
        default: 0,
      },
      reportStatus: {
        type: Boolean,
        default: false,
      },
      showCharts: {
        type: Boolean,
        default: true,
      },
      rightadmin: {
        type: Boolean,
        default: false,
      },
      rightdebug: {
        type: Boolean,
        default: false,
      },
      showDebug: {
        type: Boolean,
        default: false,
      },
      rightadvanced: {
        type: Boolean,
        default: false,
      },
      selectedVers: {
        type: Number,
        default: null,
      },
      plreportid: {
        type: Number,
        default: null,
      },
    },
    data () {
      return {
        virtualObject: {
          id: null,
          title: null,
          show: false,
        },
        snapshotDialog: false,
        snapshotTable: null,
        snapshotlist: [],
        showInfoValue: null,
        activeTable: null,
        newVersion: true,
        addingNewTable: false,
        addTableListTitle: null,
        objectid: null,
        parentTable: null,
        addTableDialog: false,
        newObjctTitle: null,
        newObjct: false,
        showTableChart: {},
        dataLoading: false,
        rowsToDelete: {},
        addToTable: null,
        addRowDialog: false,
        // objectList: [],
        changeRow: {},
        selectetObject: null,
        gettingObjects: false,
        gettingTable: {},
        addTableListID: null,
      }
    },
    computed: {
      showCellFormula () {
        return this.$store.state.cellFormula.show
      },
      openAddChartModal () {
        return this.$store.state.openAddChartModal
      },
      popupItem () {
        if (this.objectid && this.addTableListID) {
          return this.$store.state.objectsList[this.addTableListID].data.find(v => v.listdataid == this.objectid)
        } else {
          return {}
        }
      },
      tablesDataList: {
        get () {
          return this.$store.state.plTablesData[this.tab] || []
        },
        set (newVal) {
          this.$store.dispatch('updatePlTablesData', { tab: this.tab, val: newVal })
        },
      },
      comboMessages () {
        if (typeof this.selectetObject === 'string') {
          return 'Добавить новый объект'
        } else {
          return ''
        }
      },
      canEditReport () {
        return !this.reportStatus
      },
      canAddNewObjects () {
        if (!this.addToTable) return false
        return this.tablesDataList[this.addToTable].info.flgallownulls === 1
      },
      objects: {
        get: function () {
          return this.$store.getters.objects
        },
        set: function (newval) {
          this.$store.dispatch('setObjects', newval)
        },
      },
      objectsList () {
        if (this.addTableListID) {
          return this.$store.state.objectsList[this.addTableListID].data
        } else {
          return []
        }
      },
      objectsListInfo () {
        if (this.addTableListID) {
          return this.$store.state.objectsList[this.addTableListID].info
        } else {
          return []
        }
      },
    },
    watch: {
      activeTab: function () {
        this.activeTable = null
      },
      tablesList: function () {
        this.updateNewTablesData()
      },
      openAddChartModal () {
        if (this.openAddChartModal.updateTableById) {
          this.updateTableListAndDataById([this.openAddChartModal.tableId])
          this.$store.dispatch('setOpenAddChartModal', { open: false, tableId: null, updateTableById: false })
        }
      },
    },
    mounted () {
      if (this.tablesList.length > 0) {
        this.dataLoading = true
        this.updateTablesData()
      }
    },
    beforeDestroy () {
      this.tablesDataList.forEach((item) => {
        if (item.info) this.$store.commit('delPlTablesChanges', item.info.id)
      })
    },
    methods: {
      showSnapHistory (pltableid) {
        console.log('ssh')
        this.$store.dispatch('setLoading', true)
        this.$store.dispatch('getSnapshotsList', pltableid).then((resp) => {
          this.snapshotlist = resp
          this.$store.dispatch('setLoading', false)
          this.snapshotTable = pltableid
          this.snapshotDialog = true
        })
      },
      showSnapshotInfo (plsnapshotid) {
        const routeData = this.$router.resolve({
          path: 'snapshotinfo',
          query: {
            pltableid: this.snapshotTable,
            plsnapshotid: plsnapshotid,
          },
        })
        window.open(routeData.href, '_blank')
      },
      addLineBreak (jsonValue) {
        const needVals = {
          id: jsonValue.id,
          mask: jsonValue.datamask,
          type: jsonValue.main_datatype,
          plcoldataid: jsonValue.plcoldataid,
          plrowdataid: jsonValue.plrowdataid,
          parentrowdataid: jsonValue.parentrowdataid || '',
          plcolid: jsonValue.pldcolid,
          plrowid: jsonValue.pldrowid,
          canedit: jsonValue.can_edit,
          foryear: jsonValue.foryear || '',
          formonth: jsonValue.formonth || '',
          fordate: jsonValue.fordate || '',
          style: jsonValue.styles,
          styles_presentation: jsonValue.styles_presentation,
          main_value: jsonValue.main_value,
          masked_value: jsonValue.masked_value,
          pllistitemdataid: jsonValue.pllistitemdataid,
          flgAdvanced: jsonValue.flgrightsadvanced,
          objectid: jsonValue.objectid,
          sortindex: jsonValue.sortindex,
        }
        const lineBreakString = JSON.stringify(needVals, null, ' ')
        return lineBreakString
      },
      setShowInfoValue (val) {
        this.showInfoValue = val
      },
      updateVirtualObject () {
        if (this.virtualObject.id) {
          const payload = {
            pllistitemid: this.virtualObject.listdataid,
            title: this.virtualObject.title,
          }
          this.$store.dispatch('updateVirtualObject', payload).then((resp) => {
            this.$store.dispatch('getObjectList', this.addTableListID).then(() => {
              this.closeVirtualObjectDialog()
              this.popupItem.pllistitemdataid = resp[0].result
            })
          })
        } else {
          this.$store.dispatch('addVirtualObject', { pllistid: this.addTableListID, title: this.virtualObject.title }).then((resp) => {
            this.$store.dispatch('getObjectList', this.addTableListID).then(() => {
              this.closeVirtualObjectDialog()
              this.objectid = resp[0].listitemdataid
            })
          })
        }
      },
      canEditObject (id) {
        const vObj = this.objectsList.find(x => x.listitemdataid === id)
        return !!vObj.flgvirtual
      },
      delVirtualObject (id) {
        this.$refs.dlgDel.open({
          title: 'Удалить объект?',
          acceptText: 'Продолжить',
          cancelText: 'Отмена',
          dialogMaxWidth: 440,
        }).then(() => {
          this.$store.dispatch('delVirtualObject', { pllistitemid: id, pllistid: this.addTableListID }).then(() => {
            const objctIndex = this.objectsList.findIndex(x => x.listitemdataid === id)
            this.objectsList.splice(objctIndex, 1)
          })
        })
      },
      closeVirtualObjectDialog () {
        this.virtualObject = {
          id: null,
          show: false,
          title: null,
        }
      },
      showVirtualObjectDialog (id = null) {
        if (id) {
          const vObj = this.objectsList.find(x => x.listitemdataid === id)
          this.virtualObject.title = vObj.title
          this.virtualObject.id = vObj.objectid
          this.virtualObject.listdataid = vObj.listitemdataid
        } else {
          this.virtualObject.title = this.$refs.selects.lazySearch
        }
        this.virtualObject.show = true
      },
      setActiveTable (newVal) {
        this.activeTable = newVal
      },
      delTable (tableId) {
        const tdlIndex = this.tablesDataList.findIndex(x => x.info.id === tableId)
        this.tablesDataList.splice(tdlIndex, 1)
      },
      addNewTable () {
        if (this.objectid) {
          this.addingNewTable = true
          this.$store.dispatch('addNewTable', { pltableid: this.parentTable, plversionid: this.selectedVers, pllistitemdataid: this.objectid }).then((resp) => {
            this.addTableDialog = false
            this.addingNewTable = false
            this.dataLoading = true
            const payload = { plreportid: this.plreportid, plversionid: this.selectedVers }
            this.$emit('getList', payload)
            // this.$store.dispatch('getPlanningDataList', payload)
          })
        }
      },
      newTableDialogStart (info) {
        this.addTableListID = null
        this.objectid = null
        this.parentTable = info.pltableid
        this.addTableListTitle = info.pllisttitle
        if (this.objectsList[info.pllistid]) {
          this.addTableListID = info.pllistid
        } else {
          this.$store.commit('setObjectList', { id: info.pllistid, data: [] })
          this.$store.dispatch('getObjectList', info.pllistid).then((resp) => {
            this.$store.commit('setObjectList', { id: info.pllistid, data: resp })
            this.addTableListID = info.pllistid
          })
        }
        this.addTableDialog = true
        this.$nextTick(() => {
          setTimeout(() => {
            // this.$refs["newVersionName"].focus();
            this.$refs.selects.$refs.input.focus()
            this.$refs.selects.activateMenu()
          })
        })
      },
      showChart (table) {
        if (this.showTableChart[table]) {
          this.showTableChart[table] = false
        } else {
          this.showTableChart[table] = true
        }
        this.showTableChart = JSON.parse(JSON.stringify(this.showTableChart))
      },
      updateTableListAndDataById (tablesId) {
        this.$store.dispatch('setDataLoading', true)
        const tablesArray = []
        const clearTableChange = []
        const outerTabTable = []
        const getTable = (id) => this.$store.dispatch('getTableData', { id: id, flg: 1 })
        tablesId.forEach((x) => {
          const inTab = this.tablesList.find(tbl => tbl.id === x)
          if (inTab) {
            tablesArray.push(getTable(x))
            const tableIndex = this.tablesDataList.findIndex(table => table.info && table.info.id == x)
            this.tablesDataList[tableIndex].data = null
          } else {
            outerTabTable.push(x)
          }
        })
        Promise.all(tablesArray).then((resp) => {
          resp.forEach((table) => {
            if (table.info.pllistdataid === null) {
              table.col.forEach((c) => {
                if (c.maincol === 1) {
                  table.info.pllistdataid = c.dbmuid
                }
              })
            }
            const tableIndex = this.tablesDataList.findIndex(x => table.info && x.info.id === table.info.id)
            this.tablesDataList[tableIndex] = table
            clearTableChange.push(table.info.id)
            this.tablesDataList = JSON.parse(JSON.stringify(this.tablesDataList))
            this.gettingTable[table.info.id] = false
          })
        }).finally(() => {
          if (outerTabTable.length > 0) {
            this.$emit('updateTables', outerTabTable)
          }
          this.dataLoading = false
          this.$store.dispatch('clearPlTablesChanges', clearTableChange)
          this.$store.dispatch('setDataLoading', false)
        })
      },
      updateTablesData () {
        this.tablesDataList = new Array(this.tablesList.length).fill({ data: null })
        // const getTableData = (id) => this.$store.dispatch('getTableData', id)
        this.tablesList.forEach((x, i) => {
          if (!((x.pllistid && x.id === null) || !!x.flgcollapsed)) {
            this.$store.dispatch('getTableData', x.id).then((table) => {
              if (table.info.pllistdataid === null) {
                table.col.forEach((c) => {
                  if (c.maincol === 1) {
                    table.info.pllistdataid = c.dbmuid
                  }
                })
              }
              this.tablesDataList.splice(i, 1, table)
            })
          } else {
            this.tablesDataList.splice(i, 1, { info: x, data: [] })
          }
        })
        // test
        // this.tablesDataList.push(xtable)
      },
      updateNewTablesData () {
        this.tablesList.forEach((x, i) => {
          if (!((x.pllistid && x.id === null) || !!x.flgcollapsed)) {
            const tableIndex = this.tablesDataList.findIndex(y => y.info.id === x.id)
            if (tableIndex < 0) {
              this.$store.dispatch('getTableData', x.id).then((table) => {
                if (table.info.pllistdataid === null) {
                  table.col.forEach((c) => {
                    if (c.maincol === 1) {
                      table.info.pllistdataid = c.dbmuid
                    }
                  })
                }
                this.tablesDataList.splice(i, 0, table)
              })
            }
          }
        })
      },
      addRow (addToTable) {
        const newRow = {}
        let maxSortindex = 1
        if (this.tablesDataList[addToTable].data.length > 0) {
          for (let i = 0; i < this.tablesDataList[addToTable].data.length; i++) {
            const [first] = Object.keys(this.tablesDataList[addToTable].data[i])
            if (this.tablesDataList[addToTable].row[i].flgnumerated) {
              maxSortindex = this.tablesDataList[addToTable].data[i][first].sortindex + 1
            }
          }
        }
        this.tablesDataList[addToTable].col.forEach((col) => {
          newRow[col.id] = {
            status: 'new',
            id: null,
            objectid: null,
            pllistdataid: null,
            plcoldataid: col.id,
            plrowdataid: null,
            sortindex: maxSortindex,
            value_dbl: null,
            value_int: null,
            value_str: '',
            main_value: '',
            coldbmuid: col.dbmuid,
          }
          if (col.numcol === 1) {
            newRow[col.id].main_value = maxSortindex
          }
          if (col.maincol && col.dbmuid) {
            let object = {}
            if (typeof this.selectetObject === 'string') {
              this.newObjct = true
              object.title = this.selectetObject
            } else {
              object = this.objectList.find((x) => x.objectid == this.selectetObject.pllistdataid)
            }
            newRow[col.id].objectid = this.newObjct ? null : this.selectetObject.pllistdataid
            newRow[col.id].pllistdataid = this.newObjct ? null : this.selectetObject.pllistdataid
            newRow[col.id].value_str = object.title
            newRow[col.id].main_value = object.title
            newRow[col.id].maincol = 1
          }
          newRow[col.id].styles = this.tablesDataList[this.addToTable].info.rowstylebydef + ''
          if (col.colstyles) {
            newRow[col.id].styles = newRow[col.id].styles + ',' + col.colstyles
          }
        })
        this.tablesDataList[addToTable].data.splice(maxSortindex - 1, 0, newRow)
        this.$store.dispatch('setPlTablesChanges', { table: this.tablesDataList[addToTable].info.id, cell: 'addRow', changestate: true })
        this.changeRow[this.tablesDataList[addToTable].info.id] = true
        this.addRowDialog = false
        this.newObjctTitle = null
      },
      updateTableData (tableDataUpdate) {
        this.$store.dispatch('updateTableData', tableDataUpdate).then((resp) => {
          const cTables = []
          resp.forEach((x) => {
            if (x) {
              cTables.push(x)
            } else {
              cTables.push(tableDataUpdate.tabledataid)
            }
          })
          const haveParent = []
          cTables.forEach((x) => {
            this.tablesDataList.forEach(tabl => {
              if (tabl.info.parenttabledataid === x) {
                if (!~cTables.findIndex(x => x === tabl.info.id)) {
                  haveParent.push(tabl.info.id)
                }
              }
            })
          })
          if (haveParent.length > 0) {
            Array.prototype.push.apply(cTables, haveParent)
          }
          this.updateTableListAndDataById(cTables)
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
        })
      },
      updateTableDataLite (tableDataUpdate) {
        this.$store.dispatch('updateTableDataLite', tableDataUpdate).then((resp) => {
          const cTables = []
          resp.forEach((x) => {
            if (x) {
              cTables.push(x)
            } else {
              cTables.push(tableDataUpdate.tabledataid)
            }
          })
          const haveParent = []
          cTables.forEach((x) => {
            this.tablesDataList.forEach(tabl => {
              if (tabl.info.parenttabledataid === x) {
                if (!~cTables.findIndex(x => x === tabl.info.id)) {
                  haveParent.push(tabl.info.id)
                }
              }
            })
          })
          if (haveParent.length > 0) {
            Array.prototype.push.apply(cTables, haveParent)
          }
          this.updateTableListAndDataById(cTables)
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
        })
      },
      getObjectList ([objId, tableInfo]) {
        this.gettingObjects = true
        this.addToTable = null
        this.selectetObject = null
        if (this.objects[objId]) {
          this.objectList = this.objects[objId]
          this.gettingObjects = false
        } else {
          this.$store.dispatch('getObjectList', objId).then((resp) => {
            this.objectList = resp
            this.objects[objId] = resp
            this.gettingObjects = false
          }).catch((e) => {
            this.gettingObjects = false
          })
        }
      },
    },
  }
</script>

<style lang="scss">
.addition-info {
  all: initial;
  position: fixed;
  display: block;
  top: 5px;
  right: 5px;
  background-color: rgba(0, 202, 227, 0.86);
  width: 260px;
  height: fit-content;
  z-index: 2000;
  word-break: break-word;
  white-space: break-spaces;
  padding: 6px;
  margin-bottom: 0;
}

.beta-labl {
  position: absolute;
  left: -15px;
  top: -5px;
  height: 25px;
  background-color: #c3ffc3;
  border-radius: 10px;
  opacity: 0.5;
}
.tbi-none {
  position: relative;
  padding-top: 40px;
}
.header-fixed__table {
  position: absolute;
  top: 0;
  right: 0;
  width: calc(100% + 24px);
  z-index: 1;
}
.table-copy {
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  display: none;
}
.table-wrapper {
  .header-fixed__table {
    background: white;
    position: fixed;
    top: 110px;
    left: 88px;
    z-index: 2;
    width: calc(100% - 97px);
    height: 50px;
    .text-right {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .table-copy {
    display: table;
    position: fixed;
    top: 148px;
    z-index: 1;
    td {
      border: 1px solid black;
    }
    .collapse-col {
      background-color: white;
      border: transparent;
      border-top: 1px solid white;
    }
  }
}
.append-el {
  td {
    //.header-cell{
    //  width: 100%;
    //}
  }
}
td.collapse-col div {
  background: none;
}
.cell-border tbody tr td.collapse-col {
  border: none!important;
}
.collapse-row {
  cursor: pointer;
  text-align: left!important;
  margin: 0px;
  height: 14px;
  width: 14px;
  border: 1px solid #3c4858
}
.triangle div {
  overflow: hidden;
  position: inherit;
}
.triangle div::after {
  content: ' ';
  position: absolute;
  top: -2px;
  left: -45px;
  width: 40px;
  height: 20px;
  -webkit-transform: rotate(45deg) translate(-3px, -28px);
  background: #ff9797;
}
/*.triangle div::after {*/
/*  content: '☉';*/
/*  position: absolute;*/
/*  top: -2px;*/
/*  left: 0;*/
/*  !*width: 40px;*!*/
/*  height: 0px;*/
/*  color: red;*/
/*  font-size: 13px;*/
/*  !*-webkit-transform: rotate(45deg) translate(0px, -25px);*!*/
/*  !*background: green;*!*/
/*}*/
.vcentred {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}
.hidden-row  td:after {
  position: absolute;
  z-index: 1;
  text-align: center;
  left: 0;
  top: 0;
  width: 252%;
  height: .2em;
  content:"";
  background-color: rgba(255, 0, 0, .7);
}
.v-middle {
  /*position: absolute!important;*/
  /*top: 50%;*/
  /*transform: translateY(-50%);*/
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.tbi-none {
  outline:none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*table tr td.chosen div{*/
/*  !*border: 2px solid #4896da!important;*!*/
/*  box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
/*}*/

table tr td.chosen-in:not(.chosen) {
  border: 1px solid #c9c9c9 !important;
  /*border: 1px solid #858585#c9c9c9 !important;*/
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}

table tr td.chosen-left:not(.chosen) {
  border-left: 1px double #00b2f9!important;
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}
/*table tr td.chosen-left:not(.chosen) div{*/
/*  border-left: 1px solid #00b2f9!important;*/
/*  !*box-shadow: inset 1px 1px 0px 1px #00b2f9;*!*/
/*}*/
table tr td.chosen-top:not(.chosen) {
  border-top: 1px double #00b2f9!important;
  /*box-shadow: inset 1px 1px 0px 1px #00b2f9;*/
}
/*table tr td.chosen-top:not(.chosen) div{*/
/*  border-top: 1px solid #00b2f9!important;*/
/*  !*box-shadow: inset 1px 1px 0px 1px #00b2f9;*!*/
/*}*/
table tr td.chosen-right:not(.chosen) {
  border-right: 1px solid #00b2f9!important;
}
/*table tr td.chosen-right:not(.chosen) div {*/
/*  border-right: 1px solid #00b2f9!important;*/
/*}*/
table tr td.chosen-bottom:not(.chosen) {
  border-bottom: 1px solid #00b2f9!important;
}

/*table tr td.chosen-bottom.chosen-right:not(.chosen):after {*/
/*  position: absolute;*/
/*  bottom: 0px;*/
/*  right: 0px;*/
/*  content: ' ';*/
/*  width: 5px;*/
/*  height: 5px;*/
/*  background-color: #00b2f9;*/
/*}*/
/*table tr td.chosen-bottom:not(.chosen) div{*/
/*  border-bottom: 1px solid #00b2f9!important;*/
/*}*/
table tr td.chosen {
  border: 1px double #4793d5 !important;
}
table tr td.chosen div{
  box-shadow: inset 0px 0px 0px 1px #4793d5 !important;
}

/*table tr td.cell {*/
/*  box-shadow: inset 0px 0px 0px 1px #4793d5 !important;*/
/*}*/

table tr td.cell {
  border: none!important;
  padding: 0;
  margin: 0;
}
table tr td.cell input:focus {
  border: 0px solid #4793d5 !important;
  background: none;
}

.chosenRow:before {
  content: "";
  position: absolute;
  left: 0; right: 0;
  top: 0; bottom: 0;
  background: rgba(0,0,0,.05);
}
.canEdit {
  cursor: pointer;
}
.cell-input {
  width: 100%;
  height: 100%;
  padding-left: 4px;
}
.withoutbg {
  background: none;
}

.select-border {
  background-color: #2d6da3;
}
.header-cell {
  width: 100%;
  height: 100%;
}
table.cell-border {
  clear: both;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.cell-border th {
  padding: 0!important;
}
/*.cell-border thead tr th:first-child, .cell-border tbody tr td:first-child {*/
/*  border-left: 1px solid rgba(0, 0, 0, 1);*/
/*}*/
/*.cell-border thead tr th {*/
/*  padding: 0!important;*/
/*}*/
.cell-border tr th, .cell-border tr td {
  border: 1px solid black!important;
  padding: 0!important;
  overflow: hidden;
}
.cell-border tr th, .cell-border tr .last-modified-td {
  border: 1px double #e7e5e5 !important;
}
.last-modified {
  box-shadow: inset 0px 0px 0px 1px #e7e5e5 !important;
}
/*.cell-border tbody td {*/
/*  border-top: 1px solid rgba(0, 0, 0, 1);*/
/*  border-right: 1px solid rgba(0, 0, 0, 1);*/
/*}*/

/*.list .row {*/
/*  display: flex;*/
/*}*/

revogr-viewport-scroll.col {
  padding: 0;
}
.checkit{
  background-color: salmon;
}
.row.fit {
  display: block;
  margin: 0;
  text-align: center;
}

.custom-list-group:hover .v-list-group__header {
  background-color: #f6f6f6;
}

.custom-list-group {
  .v-list-group__items {
    display: none;
    .theme--light {
      display: flex;
      flex-direction: column;
      align-self: start;
      padding: 0;
      &:after {
        display: none;
      }
      .theme--light {
        padding: 0 16px;
        width: 100%;
        height: 20px;
        &:hover {
          background-color: #f6f6f6;
        }
      }
      .v-list-item {
        min-height: 25px;
      }
      .v-list-item__title {
        display: flex;
        align-items: center;
        align-self: start;
      }
      .v-list-item--link {
        &:after {
          height: 48px;
          display: none;
        }
        &:before {
          height: 48px;
          display: none;
        }
      }
    }
  }
}

.custom-list-group:hover .v-list-group__items {
  display: block;
}
.auto-sum-disable {
  display: none;
}
</style>
