<template>
  <v-dialog
    v-model="showAddChart"
    scrollable
    max-width="800px"
    :fullscreen="fullscreen?true:false"
  >
    <v-card>
      <v-card-title>
        Добавить график
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          lazy-validation
        >
          <v-row>
            <v-col sm="6">
              <v-text-field
                v-model="baseChartPattern.title"
                label="Название графика:"
                required
                clearable
              />
            </v-col>
            <v-col sm="6">
              <v-select
                v-model="baseChartPattern.chartType"
                :items="chartTypes"
                item-text="title"
                label="Тип отображения графика:"
                required
                clearable
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeAddChartModal"
        >
          отмена
        </v-btn>
        <v-spacer />
        <v-btn
          color="green darken-1"
          text
          @click="addChart"
        >
          добавить
        </v-btn>
        <v-spacer />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'modalAddChart',
    data () {
      return {
        fullscreen: false,
        showAddChart: false,
        chartTypes: [
          'line', 'bar', 'stackedbar', 'spline',
          'area', 'fullstackedarea', 'fullstackedbar',
          'fullstackedline', 'fullstackedspline',
          'fullstackedsplinearea', 'rangearea', 'rangebar',
          'scatter', 'spline', 'splinearea', 'stackedarea',
          'stackedbar', 'stackedline', 'stackedspline',
          'stackedsplinearea', 'steparea', 'stepline',
        ],
        chartAddInfoPltableid: null,
        baseChartPattern: {
          args: [],
          vols: [],
          chartType: 'bar',
          chartArgsAxis: 'col',
          showZero: false,
          showZero2: false,
          maincol: [],
          multiChart: false,
          synchronizeChart: false,
          args2: [],
          vols2: [],
          chartType2: null,
          chartArgsAxis2: 'col',
          maincol2: [],
          title: null,
          legendFontSize: 20,
          axisFontSize: 20,
          rangeStart1: null,
          rangeEnd1: null,
          rangeStart2: null,
          rangeEnd2: null,
        },
        tableId: null,
      }
    },
    methods: {
      async addChart () {
        this.closeAddChartModal()
        const payload = {
          plchartid: null,
          pltableid: this.chartAddInfoPltableid,
          pattern: JSON.stringify(this.baseChartPattern),
          descript: '',
          title: this.baseChartPattern.title,
        }
        await this.$store.dispatch('changeTableChartPattern', payload).then(() => {
          const text = 'Данные обновлены'
          this.$store.dispatch('setSnackbar', { state: true, text: text, color: 'success', direction: 'top center' })
        }).then(() => {
          this.$store.dispatch('setOpenAddChartModal', { open: false, tableId: this.tableId, updateTableById: true })
        })
      },
      openModal (tableId) {
        this.tableId = tableId
        for (const key in this.tablesDataList) {
          for (const table in this.tablesDataList[key]) {
            if (this.tablesDataList[key][table].info.id === tableId) {
              this.chartAddInfoPltableid = this.tablesDataList[key][table].info.pltmpltableid
              return
            }
          }
        }
      },
      closeAddChartModal () {
        this.showAddChart = false
        this.$store.dispatch('setOpenAddChartModal', { open: false, tableId: null, updateTableById: false })
      },
    },
    computed: {
      openAddChartModal () {
        return this.$store.state.openAddChartModal
      },
      tablesDataList () {
        return this.$store.state.plTablesData
      },
    },
    watch: {
      openAddChartModal (value) {
        if (value.open) {
          this.openModal(value.tableId)
          this.showAddChart = value.open
        }
      },
    },
  }
</script>

<style scoped>

</style>
