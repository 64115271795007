<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card class="pa-4 mt-1">
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="chosenCat"
            :items="planningCategories"
            item-value="id"
            item-text="title"
            label="Категория"
            :disabled="catLoading"
            :loading="catLoading"
            @change="getTemplate"
          />
          <v-select
            v-model="chosenRep"
            :items="planningReports"
            item-value="id"
            item-text="title"
            label="Шаблон"
            :loading="plreportsLoading"
            :disabled="planningReports.length === 0 || plreportsLoading"
            @change="getAreasList"
          />
          <v-switch
            v-model="showCharts"
            :label="`Показать диаграммы`"
          />
          <v-switch
            v-if="rightdebug"
            v-model="showDebug"
            :label="'Показать debug (ctl + b)'"
            class="pt-0 mt-1"
          />
          <!--          <v-btn @click="slideMode(148)">-->
          <!--            slideMode-->
          <!--          </v-btn>-->
        </v-col>
        <v-col cols="2">
          <v-toolbar
            dense
            elevation="2"
          >
            <v-subheader class="pl-0">
              Центры учета
            </v-subheader>
            <v-progress-linear
              :active="areasLoading"
              indeterminate
              absolute
              bottom
            />
          </v-toolbar>
          <v-list
            v-if="areasListSelected.length > 0"
            class="elevation-2"
            style="max-height: 170px; overflow-y: auto;"
            dense
          >
            <v-list-item-group
              v-model="selectedArea"
              color="primary"
            >
              <v-divider />
              <template v-for="area in areasListSelected">
                <v-list-item
                  :key="'area-' + area.areaid"
                  @click="getVersions(chosenRep, area.areaid)"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ area.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="area.areaid" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col cols="2">
          <v-toolbar
            dense
            elevation="2"
          >
            <v-subheader class="pl-0">
              Периоды
            </v-subheader>
            <v-progress-linear
              :active="versiosLoading"
              indeterminate
              absolute
              bottom
            />
          </v-toolbar>
          <v-list
            v-if="versionsKeys.length > 0"
            class="elevation-2"
            style="max-height: 170px; overflow-y: auto;"
            dense
          >
            <v-list-item-group
              v-model="selectedPeriod"
              color="primary"
              :mandatory="!(selectedPeriod === null || selectedPeriod === undefined)"
            >
              <v-divider />
              <template v-for="(ver) in versionsKeys">
                <v-list-item
                  :key="'per-' + ver.key"
                  :value="ver"
                  @click="checkPeriod()"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ ver.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="'per-d-' + ver.key" />
              </template>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col
          class="text-center"
          cols="5"
        >
          <v-toolbar
            dense
            elevation="2"
          >
            <v-subheader class="pl-0">
              Версии
            </v-subheader>
          </v-toolbar>
          <v-list
            v-if="selectedPeriod && versions[selectedPeriod.key].pd.length > 0"
            class="elevation-2 text-left"
            style="max-height: 170px; overflow-y: auto;"
            dense
          >
            <template v-if="noversions">
              <v-list-item-group
                v-model="selectedVers"
                color="primary"
                :mandatory="!(selectedVers === null || selectedVers === undefined)"
              >
                <v-divider />
                <template v-for="(ver, index) in versions[selectedPeriod.key].pd">
                  <v-list-item
                    :key="'ver-' + ver.plversionid"
                    :value="ver.plversionid"
                    @click="getTables(ver)"
                  >
                    <v-list-item-icon class="mr-3">
                      <v-icon
                        color="blue-grey lighten-2"
                        style="position: absolute; left:10px; top: 8px"
                        v-text="blockStatusIcon(ver)"
                      />
                      <v-icon
                        color="amber accent-2"
                        style="position: absolute; left:28px; top: 17px"
                        v-text="mainStatusIcon(ver)"
                      />
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ ver.title }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="rightadvanced || rightadmin"
                      class="d-inline"
                    >
                      <v-icon
                        v-if="ver.flgstatus === 0 && !periodApproved && (rightadvanced || rightadmin)"
                        small
                        color="blue-grey lighten-2"
                        class="mx-2"
                        @click.stop="editVersionTitle(ver)"
                        v-text="'mdi-pencil'"
                      />
                      <v-tooltip
                        v-if="!!ver.haspresentation"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            color="blue-grey lighten-3"
                            min-width="0"
                            class="px-2 mx-1"
                            small
                            :loading="statusUpdating"
                            :disable="statusUpdating"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="moveToPresentation(ver)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-microsoft-powerpoint'"
                            />
                          </v-btn>
                        </template>
                        <span>Перейти на страницу презентации</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgmain === 1 && ver.flgstatus === 0 && !periodApproved && (rightadvanced || rightadmin)"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            color="blue-grey lighten-3"
                            min-width="0"
                            class="px-2 mx-1"
                            small
                            :loading="statusUpdating"
                            :disable="statusUpdating"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="updateOuterData(ver)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-database-sync-outline'"
                            />
                          </v-btn>
                        </template>
                        <span>Обновить внешние данные</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgmain !== 1 && !periodApproved"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            color="blue-grey lighten-2"
                            min-width="0"
                            class="px-2 mx-1"
                            small
                            :loading="statusUpdating"
                            :disable="statusUpdating"
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="setAsMainVersion(ver, index)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-star'"
                            />
                          </v-btn>
                        </template>
                        <span>Сделать основной версией</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgmain === 1 && ver.flgstatus === 1 && !periodApproved"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="px-2 mx-1"
                            color="blue-grey lighten-2"
                            min-width="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="copyVersion(ver)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-file-document-multiple'"
                            />
                          </v-btn>
                        </template>
                        <span>Копировать версию</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgmain === 1 && ver.flgstatus === 0 && !periodApproved"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="px-2 mx-1"
                            color="blue-grey lighten-2"
                            min-width="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="blockReportVersion(ver, index)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-lock'"
                            />
                          </v-btn>
                        </template>
                        <span>Заблокировать версию</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgmain === 1 && ver.flgstatus === 1 && !periodApproved"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="px-2 mx-1"
                            color="blue-grey lighten-2"
                            min-width="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="unBlockReportVersion(ver, index)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-lock-open-variant'"
                            />
                          </v-btn>
                        </template>
                        <span>Разблокировать версию</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgmain === 1 && ver.flgstatus === 1 && rightadmin && !periodApproved"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="px-2 mx-1"
                            color="blue-grey lighten-2"
                            min-width="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="approveReportVersion(ver, index)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-shield-lock'"
                            />
                          </v-btn>
                        </template>
                        <span>Утвердить версию</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="ver.flgstatus === 2 && rightadmin"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="px-2 mx-1"
                            color="blue-grey lighten-2"
                            min-width="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="unApproveReportVersion(ver, index)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-shield-remove'"
                            />
                          </v-btn>
                        </template>
                        <span>Разутвердить версию</span>
                      </v-tooltip>
                      <v-tooltip
                        v-if="(ver.flgmain !== 1 || versions[selectedPeriod.key].pd.length === 1) && ver.flgstatus !== 2 && rightadmin"
                        bottom
                      >
                        <template #activator="{ on, attrs }">
                          <v-btn
                            class="px-2 mx-1"
                            color="red lighten-1"
                            min-width="0"
                            small
                            v-bind="attrs"
                            v-on="on"
                            @click.stop="unActiveteReportVersion(ver, index)"
                          >
                            <v-icon
                              small
                              v-text="'mdi-delete'"
                            />
                          </v-btn>
                        </template>
                        <span>Удалить версию</span>
                      </v-tooltip>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider :key="'ver-div-' + ver.plversionid" />
                </template>
                <v-list-item
                  v-if="!periodApproved && (rightadvanced || rightadmin)"
                  @click.native.prevent="addVersion"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      color="orange lighten-5"
                    >
                      <v-icon>
                        mdi-plus-circle-outline
                      </v-icon>
                      создать версию
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </template>
            <template v-else-if="selectedPeriod">
              <v-list-item-group
                color="primary"
              >
                <v-list-item
                  @click.native.prevent="addVersion"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      color="orange lighten-5"
                    >
                      <v-icon>
                        mdi-plus-circle-outline
                      </v-icon>
                      создать версию
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </template>
          </v-list>
        </v-col>
      </v-row>
    </v-card>
    <v-card v-if="reportsTabs.length>0">
      <v-tabs
        v-model="tab"
        active-class="active-tab"
        class="sticky planing-tabs"
        slider-size="1"
        show-arrows
      >
        <v-tab
          v-for="(tabs, indx) in reportsTabs"
          :key="'tab-' + indx"
          class="ma-1"
        >
          {{ tabs }}
        </v-tab>
      </v-tabs>
      <v-slide-group
        v-if="tablesList"
        show-arrows
        class="pa-1 sticky-2"
      >
        <v-slide-item
          v-for="(n, i) in tablesList"
          :key="'tablist-' + n.id"
          v-slot="{ active }"
        >
          <v-btn
            class="mx-1 my-1 px-2"
            :input-value="active"
            outlined
            small
            color="gray"
            @click="scrollToTable(n,i)"
          >
            {{ n.title }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
      <v-breadcrumbs
        class="py-0 mb-0 sticky-0"
        :items="breadcrumbs"
      />
<!--      <v-btn-->
<!--        class="btn-with-icon"-->
<!--        color="green"-->
<!--        @click="openAddTableModal"-->
<!--      >-->
<!--        Создать таблицу-->
<!--        <v-icon-->
<!--          large-->
<!--        >-->
<!--          mdi-table-large-plus-->
<!--        </v-icon>-->
<!--      </v-btn>-->
      <div
        v-if="reportStatus"
        style="border-radius:8px;width: 254px; position: absolute; top: 84px; right: 20px; z-index: 20; text-align: center; background-color: #fff2d9"
      >
        [версия доступна только для чтения]
      </div>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="i in reportsTabs"
          :key="i"
        >
          <tables-list
            v-if="planningTables[i].length > 0"
            :show-charts="showCharts"
            :tables-list="planningTables[i]"
            :rightadmin="rightadmin"
            :rightdebug="rightdebug"
            :showDebug="showDebug"
            :rightadvanced="rightadvanced"
            :report-status="reportStatus"
            :selected-vers="selectedVers"
            :tab="i"
            :active-tab="tab"
            :plreportid="chosenRep"
            @getList="getTablesUpdate"
            @updateTables="updateTablesData"
            @updateTableList="getTables(lastGettingVers, true)"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog
      v-model="changeVersionNameDialog"
      persistent
      width="300"
    >
      <v-card>
        <v-card-title>Изменить название версии?</v-card-title>
        <v-card-text>
          <v-text-field
            v-model="changeVersionNameDataTitle"
            label="Заголовок"
          />
        </v-card-text>
        <v-card-actions class="mt-3">
          <v-spacer />
          <v-btn
            color="success"
            @click="saveNewVersionName"
          >
            сохранить
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="cancelNewVersionName"
          >
            отменить
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <data-loading />
    <v-dialog
      v-model="createNewVersionDialog"
      persistent
      width="300"
    >
      <v-card>
        <v-card-title>
          Создание новой версии
        </v-card-title>
        <v-card-text>
          <v-text-field
            ref="newVersionName"
            v-model="newVersionName"
            label="Название версии"
            @keydown.enter.native.stop.prevent="addVersionSubmit"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="!newVersionName"
            @click="addVersionSubmit"
          >
            создать
          </v-btn>
          <v-spacer />
          <v-btn
            color="warning"
            @click="cancelCreateVersion"
          >
            отмена
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <mdialog ref="dlg" />
    <v-fab-transition
      v-if="false"
    >
      <v-tooltip
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            color="red darken-2"
            v-bind="attrs"
            v-on="on"
            fab
            dark
            small
            fixed
            bottom
            right
            style="bottom: 25px; right: 65px;"
            @click="saveAllChanges"
          >
            <v-icon>mdi-content-save-all</v-icon>
          </v-btn>
        </template>
        <span>Сохранить все изменения</span>
      </v-tooltip>
    </v-fab-transition>
    <v-fab-transition
      v-if="offsetTop > windowHeight/3"
    >
      <v-btn
        color="blue-grey darken-2"
        fab
        dark
        small
        fixed
        bottom
        right
        style="bottom: 25px"
        @click="scrollToTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-snackbar
      v-model="snackbarAutoSum.state"
      :timeout="0"
      :close-on-click="true"
      color="#48C666"
      content-class="snackbar-auto-sum"
    >
      {{ snackbarAutoSum.text }}
      <template v-slot:action="{ attrs }">
        <span></span>
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="closeSnackbarAutoSum"
        >
          Закрыть
        </v-btn>
      </template>
    </v-snackbar>
    <modal-add-chart />
  </v-container>
</template>

<script>
  import tablesList from './tablesList'
  import modalAddChart from './modalAddChart'
  import dataLoading from '../components/dataLoading'
  import mdialog from '../../../components/mdialog'
  export default {
    name: 'Planning',
    components: {
      tablesList,
      modalAddChart,
      dataLoading,
      mdialog,
    },
    data () {
      return {
        offsetTop: 0,
        windowHeight: window.innerHeight,
        createNewVersionDialog: false,
        newVersionName: null,
        versiosLoading: false,
        areasLoading: false,
        plreportsLoading: false,
        catLoading: true,
        changeVersionNameDialog: false,
        changeVersionNameData: null,
        changeVersionNameDataTitle: null,
        statusUpdating: false,
        lastGettingVers: {},
        fullscreen: false,
        showCharts: false,
        testTables: [], // { id: 108 },  { id: 65 }, { id: 38 }, { id: 46 }, { id: 54 }, { id: 62 }],
        selectedPeriod: null,
        selectedArea: null,
        selectedVers: null,
        versions: [],
        areasList: {},
        areasListSelected: {},
        tab: 0,
        chosenRep: null,
        chosenCat: null,
        planningCategories: [],
        planningReports: [],
        planningReportsGets: {},
        planningTablesList: [],
        planningTablesGets: {},
        planningTables: null,
        dataGridInstance: null,
        cellValue: '',
        tableData: {},
        tables: [],
        dialogMaxWidth: '400px',
        showDebug: false,
      }
    },
    computed: {
      isEditing () {
        let state = false
        if (Object.keys(this.$store.state.plTablesChanges).length !== 0) {
          Object.keys(this.$store.state.plTablesChanges).forEach((key) => {
            if (this.$store.state.plTablesChanges[key]) {
              for (const innerKey in this.$store.state.plTablesChanges[key]) {
                if (this.$store.state.plTablesChanges[key][innerKey]) state = true
                break
              }
            }
          })
        }
        return state
      },
      periodApproved () {
        if (this.selectedPeriod && this.versions[this.selectedPeriod] && this.versions[this.selectedPeriod].pd) {
          return !!~this.versions[this.selectedPeriod].pd.findIndex(v => v.flgstatus === 2)
        } else {
          return false
        }
      },
      breadcrumbs () {
        const bc = []
        bc.push({
          text: this.planningCategories.find(x => x.id === this.chosenCat).title,
          disabled: false,
        })
        bc.push({
          text: this.planningReports.find(x => x.id === this.chosenRep).title,
          disabled: false,
        })
        bc.push({
          text: this.areasListSelected[this.selectedArea].title,
          disabled: false,
        })
        bc.push({
          text: this.selectedPeriod.title,
          disabled: false,
        })
        bc.push({
          text: this.lastGettingVers.versionInfo.title,
          disabled: false,
        })
        return bc
      },
      tablesDataList () {
        return this.$store.state.plTablesData
      },
      snackbarAutoSum () {
        return this.$store.state.snackbarAutoSum
      },
      reportStatus () {
        if (this.periodApproved) {
          return true
        }
        if (this.lastGettingVers && (this.lastGettingVers.versionInfo.flgmain === 0 || this.lastGettingVers.versionInfo.flgstatus > 0)) return true
        return false
      },
      rightadmin () {
        const chosenRep = this.planningReports.find(x => x.id === this.chosenRep)
        return chosenRep ? !!chosenRep.rightadmin : false
      },
      rightdebug () {
        const chosenRep = this.planningReports.find(x => x.id === this.chosenRep)
        return chosenRep ? !!chosenRep.rightdebug : false
      },
      rightadvanced () {
        const chosenRep = this.planningReports.find(x => x.id === this.chosenRep)
        return chosenRep ? !!chosenRep.rightadvanced : false
      },
      noversions () {
        let haveVersId = false
        if (this.versions && this.selectedPeriod) {
          this.versions[this.selectedPeriod.key].pd.forEach((vers) => {
            if (vers.plversionid) haveVersId = true
          })
        }
        return haveVersId
      },
      tableStyles () {
        return this.$store.getters.tableStyles
      },
      url () {
        return this.$store.getters.url
      },
      tablesList () {
        console.log(this.planningTables, 'this.planningTables[this.reportsTabs[this.tab]]')
        if (this.reportsTabs) return this.planningTables[this.reportsTabs[this.tab]]
        return []
      },
      versionsKeys () {
        if (this.versions.length === 0) {
          return []
        } else {
          const vkyes = []
          this.versions.forEach((v, i) => {
            vkyes.push({ key: i, title: v.pt })
          })
          return vkyes
        }
      },
      reportsTabs () {
        if (!this.planningTables) return []
        return Object.keys(this.planningTables)
      },
    },
    watch: {
      showDebug (newVal) {
        const payload = {
          cookieName: 'showDebug',
          cookieValue: newVal,
        }
        this.$store.dispatch('setCookies', payload)
      },
      anyChange () {
        this.$store.commit('clearAllTablesFilter')
      },
      isEditing (newVal) {
        this.$store.commit('setPreventNext', newVal)
      },
    },
    created () {
      if (!this.$isServer) {
        this._scrollListener = () => {
          // window.pageX/YOffset is equivalent to window.scrollX/Y, but works in IE
          // We round values because high-DPI devies can provide some really nasty subpixel values
          this.offsetTop = Math.round(window.pageYOffset)
        }

        // Call listener once to detect initial position
        this._scrollListener()

        // When scrolling, update the position
        window.addEventListener('scroll', this._scrollListener)
      }
    },
    async mounted () {
      this.$store.dispatch('getPlanningCategories').then((resp) => {
        this.planningCategories = resp
        this.catLoading = false
      }).catch((e) => {
        console.log('Error:', e)
        this.catLoading = false
      })
      const showDebug = await this.$store.dispatch('getCookies', 'showDebug')
      if (showDebug !== null) {
        this.showDebug = JSON.parse(showDebug)
      }
      // todo избавться от вычисляемых стилей совсем
      if (this.tableStyles === null) {
        this.$store.dispatch('getTableStyles')
      }
      // eslint-disable-next-line no-prototype-builtins
      if (!this.$store.getters.cssLoad) {
        this.$store.dispatch('getStyle').then((resp) => {
          const css = resp
          const style = document.createElement('style')
          document.head.appendChild(style) // or something of the likes
          style.type = 'text/css'
          if (style.styleSheet) {
            // This is required for IE8 and below.
            style.styleSheet.cssText = css
          } else {
            style.appendChild(document.createTextNode(css))
          }
          this.$store.dispatch('setCssLoad', true)
        })
      }
      window.addEventListener('keydown', this.keyToggleDebug)
    },
    beforeDestroy () {
      // Detach the listener when the component is gone
      window.removeEventListener('scroll', this._scrollListener)
      window.removeEventListener('keydown', this.keyToggleDebug)
      this.$store.commit('setPreventNext', false)
      this.$store.dispatch('setSnackbarAutoSum', { state: false, text: '' })
    },
    methods: {
      openAddTableModal () {
        this.$store.dispatch('setOpenAddTableModal', true)
      },
      closeSnackbarAutoSum () {
        this.$store.dispatch('setSnackbarAutoSum', { state: false, text: '' })
      },
      saveAllChanges () {
        this.$root.$emit('eventSaveAllChanges')
      },
      keyToggleDebug (e) {
        if (e.ctrlKey && e.keyCode === 66) {
          this.showDebug = !this.showDebug
        }
      },
      preventNav () {
        return this.$refs.dlg
          .open({
            title: 'Не сохраненные данные \r будут потеряны!',
            acceptText: 'Все равно продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: 600,
          })
          .then(() => {
            return true
          })
          .catch(() => {
            return false
          })
      },
      scrollToTop () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
      moveToPresentation (ver) {
        const routeData = this.$router.resolve({ path: 'reportViewer', query: { plversion: ver.plversionid } })
        console.log('routeData', routeData)
        window.open(routeData.href, '_blank')
      },
      updateTablesData (tables) {
        console.log('update outer start', tables)
        const clearTableChange = []
        const getTable = (id) => this.$store.dispatch('getTableData', id)
        let tcount = tables.length
        tables.forEach((x) => {
          getTable(x).then((table) => {
            if (table.info.pllistdataid === null) {
              table.col.forEach((c) => {
                if (c.maincol === 1) {
                  table.info.pllistdataid = c.pllistdataid
                }
              })
            }
            for (const tab in this.tablesDataList) {
              const tableIndex = this.tablesDataList[tab].findIndex(y => y.info.id === table.info.id)
              if (tableIndex >= 0) {
                console.log('13231', { tab: tab, val: table, tableIndex: tableIndex.toString() })
                console.log('this.tablesDataList[tab]', this.tablesDataList[tab])
                clearTableChange.push(table.info.id)
                // todo make dispatch to store not in for {}
                this.$store.dispatch('updateOnePlTablesData', { tab: tab, val: table, tableIndex: tableIndex.toString() })
                this.$store.dispatch('clearPlTablesChanges', clearTableChange)
                this.tablesDataList[tab] = JSON.parse(JSON.stringify(this.tablesDataList[tab]))
                tcount--
                if (tcount === 0) {
                  this.dataLoading = false
                  this.$store.dispatch('setDataLoading', false)
                }
              }
            }
          }).catch((err) => {
            console.log('Error', err)
            this.$store.dispatch('setSnackbar', { state: true, text: err, color: 'warning', direction: 'top center' })
            tcount--
            if (tcount === 0) {
              this.dataLoading = false
              this.$store.dispatch('setDataLoading', false)
            }
          })
        })
      },
      async checkPeriod () {
        if (this.isEditing) {
          const popupResult = await this.preventNav()
          if (!popupResult) return
        }
        this.planningTables = {}
        this.selectedVers = null
      },
      cancelNewVersionName () {
        this.changeVersionNameDialog = false
        this.changeVersionNameData = null
        this.changeVersionNameDataTitle = null
      },
      saveNewVersionName () {
        this.$refs.dlg
          .open({
            title: 'Изменить название версии?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          })
          .then(() => {
            this.$store.dispatch('setDataLoading', true)
            const payload = {
              versionid: this.changeVersionNameData.plversionid,
              title: this.changeVersionNameDataTitle,
            }
            this.$store.dispatch('versionTitleSet', payload).then(() => {
              const text = 'Название версии изменено'
              this.changeVersionNameDialog = false
              this.$store.dispatch('setSnackbar', { state: true, text: text, color: 'success', direction: 'top center' })
              // this.getVersions(this.chosenRep, this.areasListSelected[this.selectedArea].areaid, true)
              this.$nextTick(() => {
                const findIndex = this.versions[this.selectedPeriod.key].pd.findIndex((x) => {
                  return x.plversionid === this.changeVersionNameData.plversionid
                })
                this.versions[this.selectedPeriod.key].pd[findIndex].title = this.changeVersionNameDataTitle
                this.cancelNewVersionName()
                //   this.selectedVers = vers.plversionid
              //   this.getTables(vers)
              //   this.$store.dispatch('setDataLoading', false)
              })
              this.$store.dispatch('setDataLoading', false)
            })
          }).catch((e) => {
            console.log('error', e)
          })
      },
      cancelCreateVersion () {
        this.createNewVersionDialog = false
      },
      addVersionSubmit () {
        this.$store.dispatch('setDataLoading', true)
        this.createNewVersionDialog = false
        this.selectedVers = null
        const newVersData = {
          plreportid: this.chosenRep,
          title: this.newVersionName,
          ownerid: this.$store.getters.empid,
          areaid: this.areasListSelected[this.selectedArea].areaid,
          foryear: this.versions[this.selectedPeriod.key].pd[0].foryear,
          formonth: this.versions[this.selectedPeriod.key].pd[0].formonth,
          forweek: this.versions[this.selectedPeriod.key].pd[0].forweek,
          fordate: this.versions[this.selectedPeriod.key].pd[0].fordate,
        }
        this.$store.dispatch('createNewReportVersion', newVersData).then((newVersResp) => {
          const payload = { chosenRep: this.chosenRep, areaid: this.areasListSelected[this.selectedArea].areaid }
          this.$store.dispatch('getPlanningVersionsList', payload).then((resp) => {
            const newTable = resp[this.selectedPeriod.key].pd.find(x => x.plversionid === newVersResp.version_id)
            if (this.versions[this.selectedPeriod.key].pd.length === 1 && this.versions[this.selectedPeriod.key].pd[0].plversionid === null) {
              this.versions[this.selectedPeriod.key].pd = []
              this.versions[this.selectedPeriod.key].pd.push(newTable)
            } else {
              this.versions[this.selectedPeriod.key].pd.push(newTable)
            }
            this.versiosLoading = false
            this.$nextTick(() => {
              this.$store.dispatch('setDataLoading', false)
              const versIndex = this.versions[this.selectedPeriod.key].pd.length
              this.selectedVers = newVersResp.version_id
              this.getTables(this.versions[this.selectedPeriod.key].pd[versIndex - 1])
            })
          })
        }).catch((e) => {
          this.$store.dispatch('setDataLoading', false)
          console.log('newVers:Error:', e)
        })
      },
      addVersion () {
        this.selectedVers = null
        this.newVersionName = null
        this.createNewVersionDialog = true
        this.$nextTick(() => {
          setTimeout(() => {
            this.$refs.newVersionName.$refs.input.focus()
          })
        })
      },
      setAsMainVersion (vers, index) {
        this.$refs.dlg
          .open({
            title: 'Сделать основной версией?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setDataLoading', true)
            this.statusUpdating = true
            this.$store.dispatch('setAsMainVersion', { versionid: vers.plversionid }).then(() => {
              this.versions[this.selectedPeriod.key].pd.forEach((x) => {
                if (x.flgmain === 1 && this.selectedVers === x.plversionid) {
                  this.getTables(x)
                }
                x.flgmain = 0
              })
              this.statusUpdating = false
              this.$nextTick(() => {
                this.versions[this.selectedPeriod.key].pd[index].flgmain = 1
                if (this.selectedVers === vers.plversionid) {
                  this.getTables(vers)
                }
                this.$store.dispatch('setDataLoading', false)
              })
            }).catch(() => {
              this.$store.dispatch('setDataLoading', false)
              this.statusUpdating = false
            })
          })
      },
      unActiveteReportVersion (vers, index) {
        const lastSelVewrs = this.selectedVers
        this.$refs.dlg
          .open({
            title: 'Удалить версию?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('setReportVersionActive', { versionid: vers.plversionid, status: 0 }).then(() => {
              this.versions[this.selectedPeriod.key].pd.splice(index, 1)
              this.$nextTick(() => {
                if (lastSelVewrs === index) {
                  this.selectedVers = null
                } else if (lastSelVewrs && lastSelVewrs > index) {
                  this.selectedVers = vers.plversionid
                  // this.getTables(this.versions[this.selectedPeriod][lastSelVewrs - 1])
                }
                this.$store.dispatch('setDataLoading', false)
              })
            }).catch((e) => {
              console.log('error', e)
              this.$store.dispatch('setDataLoading', false)
            })
          })
      },
      approveReportVersion (vers, index) {
        this.$refs.dlg
          .open({
            title: 'Утвердить версию?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('setReportVersionStatus', { versionid: vers.plversionid, status: 2 }).then(() => {
              this.$nextTick(() => {
                this.versions[this.selectedPeriod.key].pd[index].flgstatus = 2
                // this.getTables(vers)
              })
              this.$store.dispatch('setDataLoading', false)
            })
          })
      },
      unApproveReportVersion (vers, index) {
        this.$refs.dlg
          .open({
            title: 'Разутвердить версию?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('setReportVersionStatus', { versionid: vers.plversionid, status: 1 }).then(() => {
              this.$nextTick(() => {
                this.versions[this.selectedPeriod.key].pd[index].flgstatus = 1
                // this.getTables(vers)
              })
              this.$store.dispatch('setDataLoading', false)
            })
          })
      },
      updateOuterData (ver) {
        this.$refs.dlg
          .open({
            title: 'Выполнить обновление внешних данных версии?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.statusUpdating = true
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('recalcVersionOuterData', ver.plversionid).then(() => {
              this.$store.commit('clearObjectList')
              this.$store.dispatch('setDataLoading', false)
              this.getTables(ver, true)
              this.statusUpdating = false
            }).catch(() => {
              this.statusUpdating = false
              this.$store.dispatch('setDataLoading', false)
            })
          })
      },
      editVersionTitle (ver) {
        this.changeVersionNameDialog = true
        this.changeVersionNameData = ver
        this.changeVersionNameDataTitle = ver.title
      },
      mainStatusIcon (ver) {
        if (ver.flgmain === 1) {
          return 'mdi-star'
        } else {
          return ''
        }
      },
      blockStatusIcon (ver) {
        const statIcon = ['mdi-lock-open-variant', 'mdi-lock', 'mdi-shield-lock']
        if (Number.isInteger(ver.flgstatus)) {
          return statIcon[ver.flgstatus]
        } else {
          return 'mdi-star'
        }
      },
      copyVersion (vers) {
        this.$refs.dlg
          .open({
            title: 'Сделать копию версии?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setDataLoading', true)
            this.$store.dispatch('copyReportVersion', { versionid: vers.plversionid }).then(() => {
              this.getVersions(this.chosenRep, this.areasListSelected[this.selectedArea].areaid, true)
              this.$store.dispatch('setDataLoading', false)
            }).catch(() => {
              this.$store.dispatch('setDataLoading', false)
            })
          })
      },
      blockReportVersion (vers, index) {
        this.$refs.dlg
          .open({
            title: 'Заблокировать версию?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setReportVersionStatus', { versionid: vers.plversionid, status: 1 }).then(() => {
              // todo: update without reload
              this.$nextTick(() => {
                this.versions[this.selectedPeriod.key].pd[index].flgstatus = 1
                const temVersItem = JSON.parse(JSON.stringify(this.versions[this.selectedPeriod.key].pd[index]))
                this.versions[this.selectedPeriod.key].pd.splice(index, 1, temVersItem)
                this.selectedVers = vers.plversionid
                this.getTables(vers, true)
              })
            })
          })
      },
      unBlockReportVersion (vers, index) {
        this.$refs.dlg
          .open({
            title: 'Разблокировать версию?',
            acceptText: 'Продолжить',
            cancelText: 'Отмена',
            dialogMaxWidth: this.dialogMaxWidth,
          }).then(() => {
            this.$store.dispatch('setReportVersionStatus', { versionid: vers.plversionid, status: 0 }).then(() => {
              this.$nextTick(() => {
                this.versions[this.selectedPeriod.key].pd[index].flgstatus = 0
                const temVersItem = JSON.parse(JSON.stringify(this.versions[this.selectedPeriod.key].pd[index]))
                this.versions[this.selectedPeriod.key].pd.splice(index, 1, temVersItem)
                // this.selectedVers = vers.plversionid
                this.getTables(vers, true)
              })
            })
          })
      },
      scrollToTable (tableInfo, index) {
        const tablID = tableInfo.id || tableInfo.virtid
        console.log('tabID', tablID)
        if (document.getElementById(['dataGrid-' + tablID])) {
          const yOffset = -110
          const element = document.getElementById(['dataGrid-' + tablID])
          const y = element.getBoundingClientRect().top + window.scrollY + yOffset
          window.scrollTo({ behavior: 'smooth', top: y })
        }
      },
      clearSlected () {
        this.selectedPeriod = null
        this.selectedArea = null
        this.selectedVers = null
      },
      async getVersions (chosenRep, areaid) {
        if (this.isEditing) {
          const popupResult = await this.preventNav()
          if (!popupResult) return
        }
        this.versiosLoading = true
        this.planningTables = {}
        this.versions = []
        this.clearSlected()
        const payload = { chosenRep: chosenRep, areaid: areaid }
        this.$store.dispatch('getPlanningVersionsList', payload).then((resp) => {
          this.versions = resp
          this.versiosLoading = false
        }).catch((e) => {
          console.log('Error:', e)
          this.versiosLoading = true
        })
      },
      async getAreasList (plreportid) {
        if (this.isEditing) {
          const popupResult = await this.preventNav()
          if (!popupResult) return
        }
        this.areasLoading = true
        this.clearSlected()
        this.planningTables = {}
        this.areasListSelected = {}
        this.versions = []
        this.$store.dispatch('getPlanningAreasList', { plreportid: plreportid }).then((resp) => {
          this.areasList[plreportid] = resp
          this.areasListSelected = resp
          this.areasLoading = false
        })
      },
      async getTemplate (newval) {
        if (this.isEditing) {
          const popupResult = await this.preventNav()
          if (!popupResult) return
        }
        this.planningTables = {}
        this.plreportsLoading = true
        this.chosenRep = null
        this.clearSlected()
        this.areasListSelected = {}
        this.versions = []
        this.$store.dispatch('getPlanningReportsList', { plcategoryid: newval }).then((resp) => {
          this.planningReportsGets[newval] = resp
          this.planningReports = resp
          if (this.planningReports.length === 1) {
            this.chosenRep = this.planningReports[0].id
            this.getAreasList(this.chosenRep)
          }
          this.plreportsLoading = false
        }).catch((e) => {
          console.log('Error: ', e)
          this.plreportsLoading = false
        })
      },
      async getTables (newval, forceUpdate = false) {
        if (this.isEditing) {
          const popupResult = await this.preventNav()
          if (!popupResult) return
        }
        await this.$store.dispatch('clearFullPlTablesChanges')
        const plreportid = newval.chosenRep || this.chosenRep
        const plversionid = newval.plversionid
        this.lastGettingVers = {
          chosenRep: plreportid,
          chosenRepName: this.planningReports.find(pr => pr.id === plreportid)?.title,
          plversionid: plversionid,
          versionInfo: newval,
        }
        this.planningTables = {}
        const payload = {
          plreportid: plreportid,
          plversionid: plversionid,
        }
        this.$store.dispatch('getPlanningDataList', payload).then((response) => {
          this.$store.dispatch('getPlanningVersionListInfo', plversionid)
          for (const key in response) {
            response[key].forEach((z, i) => {
              if (!z.id) {
                z.virtid = 'xx' + i
              }
            })
          }
          this.$nextTick(() => {
            this.tab = 0
            this.planningTables = response
          })
        })
      },
      getTablesUpdate (newval, forceUpdate = false) {
        // eslint-disable-next-line no-prototype-builtins
        const plreportid = newval.chosenRep || this.chosenRep
        const plversionid = newval.plversionid
        const payload = {
          plreportid: plreportid,
          plversionid: plversionid,
        }
        this.$store.dispatch('getPlanningDataList', payload).then((response) => {
          console.log('aaa', response)
          for (const key in response) {
            response[key].forEach((z, i) => {
              if (!z.id) {
                z.virtid = 'xx' + i
              }
            })
          }
          this.$nextTick(() => {
            this.planningTables = response
          })
        })
      },
    },
  }
</script>

<style lang="scss">
@import '../../../assets/dx.llight.css';

/* stylelint-disable */
#gridContainer {
  height: 440px;
}
/* stylelint-enable */
td {
  padding: 0!important;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active.active-tab {
  color: #3c4858;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active.active-tab {
  background-color: #eaeaea;
}
.version-label {
  position: absolute;
  z-index: 2;
  right: 20px;
  top: -12px;
  height: 25px;
  border-radius: 10px;
  background-color: #e5e5e5;
}
.sticky-0 {
  position: sticky;
  top: 78px;
  z-index: 2;
  color: #a2a2a2;
  background-color: #fdfdfd;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky-2 {
  position: sticky;
  top: 35px;
  background: #FFFFFF;
  z-index: 2;
  /*border-bottom: 1px solid #797979;*/
  box-shadow: 0 2px 4px -2px rgba(77, 94, 71, 0.45);
}
.v-tabs-bar {
  height: 35px!important;
}
.snackbar-auto-sum {
  color: white;
  font-size: 14px;
}
.planing-tabs {
  .v-slide-group__wrapper {
    display: flex;
    overflow: hidden;
  }
}
.v-btn__content {
  gap: 15px;
}
.btn-with-icon {
  margin: 20px auto !important;
  display: flex;
}
</style>
